<template>
    <CRow>
        <CCol>
            <CCard>
                <CCardBody>
                    <template>
                        <CIcon name="cil-calendar" style="margin-bottom: 5px" class="text-danger">
                        </CIcon>
                        <label style="
                    padding-left: 12px;
                font-size: 20px;
                margin-bottom: 0;
                cursor: pointer;
                color: #321fdb;
              ">
                            Upcoming booking
                        </label>
                        <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isLoading === true"
                            color="primary" />
                        <CDataTable v-if="isLoading == false" hover columnFilter sorter sortable :items.sync="internalItems"
                            :fields="fields" :items-per-page="10">
                            <template #bookingNumber="{ item }">
                                <td>
                                    <strong>{{ item.bookingNumber }}</strong>
                                </td>
                            </template>
                            <template #customer="{ item }">
                                <td>
                                    <strong>{{ item.fullName }} </strong>
                                </td>
                            </template>
                            <template #bookingDates="{ item }">
                                <td>
                                    <strong>{{ moment(new Date(item.bookingDates.split(" - ")[0])).format($dateFormat).split(" ")[0] + " - " +  moment(new Date(item.bookingDates.split(" - ")[1])).format($dateFormat).split(" ")[0] }} </strong>
                                </td>
                            </template>
                            <template #guestNames="{ item }">
                                <td>
                                    <strong>{{ item.guestNames }} </strong>
                                </td>
                            </template>
                            <template #createDate="{ item }">
                                <td>
                                    <strong>{{ moment(new Date(item.createDate)).format($dateFormat) }}</strong>
                                </td>
                            </template>
                            <template #bookingTotal="{ item }">
                                <td>
                                    <strong>{{ item.bookingTotal }}</strong>
                                </td>
                            </template>
                            <template #state="{ item }">
                                <td>
                                    <CBadge v-if="item.state < 3" :color="getBadge(item.state)">
                                        {{ item.state == 2 ? "Paid" : "Unpaid" }}
                                    </CBadge>
                                    <CBadge v-if="item.state == 3" color="danger">
                                        Cancelled
                                    </CBadge>
                                    <CBadge v-if="item.state == 4" color="danger">
                                        Expired
                                    </CBadge>
                                    <CBadge v-if="item.state == 5" color="light">
                                        Confirmed
                                    </CBadge>
                                    <CBadge v-if="item.state == 6" color="success">
                                        Partial Paid
                                    </CBadge>
                                </td>
                            </template>
                            <template #ACTIONS="{ item }">
                                <td style="width: 15%">
                                    <CButton color="info" @click="getDetails(item.id)">
                                        <CIcon name="cil-list" /> Details
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </template>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>
  
<script>
import axios from "axios";

export default {
    name: "WidgetUpcomingBookings",
    data: () => {
        return {
            count: 0,
            internalItems: [],
            isLoading: true,
            fields: [
                {
                    key: 'bookingNumber',
                    filter: false,
                    sorter: false
                },
                {
                    key: 'bookingDates',
                    filter: false,
                    sorter: false
                },
                {
                    key: 'customer',
                    filter: false,
                    sorter: false
                },
                {
                    key: 'guestNames',
                    filter: false,
                    sorter: false
                },
                {
                    key: 'createDate',
                    filter: false,
                    sorter: false
                },
                {
                    key: 'bookingTotal',
                    filter: false,
                    sorter: false
                },
                {
                    key: 'state',
                    filter: false,
                    sorter: false
                },
                {

                    key: 'ACTIONS',
                    sorter: false,
                    filter: false
                }
            ],
        };
    },

    computed: {},
    methods: {
        getBadge(status) {
            switch (status) {
                case 2:
                    return "success";
                case 1:
                    return "secondary";
                default:
                    return "primary";
            }
        },
        getDetails(id) {
            //visiblePopUp
            if (id != "00000000-0000-0000-0000-000000000000" && id != null) {
                const link = `/bookings/details/${id.toString()}`;
                this.$router.push({ path: link });
            }
            else {
                this.visiblePopUp = true;
            }

        },

        goToBookings() {
            const link = `/bookings`;
            this.$router.push({ path: link });
        },
        getTableList() {
            let self = this;
            axios
                .get(
                    this.$apiAdress +
                    "/v1/Booking/upcoming-bookings"
                )
                .then(function (response) {
                    self.internalItems = [];
                    self.bookings = response.data;
                    self.bookings.map(function (value, key) {
                        value.bookingNumber = value.bookingNumber;
                        value.bookingTotal = value.bookingTotal;
                        value.fullName =
                            value.customer.firstName + " " + value.customer.lastName;
/*                         value.createDate = new Date(value.createDate)
                            .toISOString()
                            .split("T")[0]; */

                        value.bookingDates =
                            new Date(value.startDate).toISOString().split("T")[0] +
                            " - " +
                            new Date(value.endDate).toISOString().split("T")[0];

/*                         value.createDate = new Date(value.createDate)
                            .toISOString()
                            .split("T")[0]; */

                        self.internalItems.push(value);
                    });
                    self.isLoading = false;

                })
                .catch(function (error) {
                              self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
                    self.message = error;
                    self.isLoading = false;

                });

        }
    },
    mounted: function () {
        this.getTableList();
    },
};
</script>
  