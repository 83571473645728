<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardBody>
          <template>
            <CIcon
              name="cil-calendar"
              style="margin-bottom: 5px"
              class="text-danger"
            ></CIcon>
            <label
              v-text="count"
              @click="goToBookings()"
              style="
                padding-left: 12px;
                font-size: 20px;
                margin-bottom: 0;
                cursor: pointer;
                color: #321fdb;
              "
            ></label>
            <!-- <CButton
              color="link"
              @click="goToBookings()"
              style="padding-left: 5px; padding-top: 0"
              ><clabel> {{ count }} </clabel> 
            </CButton> -->
          </template>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";

export default {
  name: "WidgetsBooking",
  data: () => {
    return {
      count: 0,
    };
  },

  computed: {},
  methods: {
    getCount() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/booking/count`)
        .then(function (response) {
          self.count = response.data;
        })
        .catch(function (error) {});
    },

    goToBookings() {
      const link = `/bookings`;
      this.$router.push({ path: link });
    },
  },
  mounted: function () {
    this.getCount();
  },
};
</script>
