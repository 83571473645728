<template>
  <div>
    <CRow>
      <CCol col="3">
        <WidgetsDropdown />
      </CCol>
      <CCol col="3">
        <WidgetsBooking />
      </CCol>
      <CCol col="3">
        <WidgetsCustomer />
      </CCol>
      <CCol col="3"></CCol>
    </CRow>
    <CRow>
      <CCol>
        <WidgetUpcomingBookings></WidgetUpcomingBookings>

      </CCol>
    </CRow>
  </div>
</template>

<script>
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBooking from "./widgets/WidgetsBooking";
import WidgetsCustomer from "./widgets/WidgetsCustomer";
import WidgetUpcomingBookings from "./widgets/WidgetUpcomingBookings"
export default {
  name: "Dashboard",
  components: {
    WidgetsDropdown,
    WidgetsBooking,
    WidgetsCustomer,
    WidgetUpcomingBookings
  },
};
</script>
