<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardBody>
          <template>
            <CIcon
              name="cil-briefcase"
              style="margin-bottom: 5px"
              class="text-danger"
            ></CIcon>
            <label v-text="partnerName" style="padding-left: 12px"></label>
          </template>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "WidgetsDropdown",
  data: () => {
    return {
      partnerName: localStorage.getItem("partner_name"),
    };
  },
};
</script>
